/* @font-face {
    font-family: BebasBold;
    src: url(../../assets/fonts/BebasNeueBold.ttf);
}
@font-face {
    font-family: BebasBook;
    src: url(../../assets/fonts/BebasNeueBook.ttf);
}
@font-face {
    font-family: BebasLight;
    src: url(../../assets/fonts/BebasNeueLight.ttf);
}
@font-face {
    font-family: BebasRegular;
    src: url(../../assets/fonts/BebasNeueRegular.ttf);
}
@font-face {
    font-family: GothamMedium;
    src: url(../../assets/fonts/GothamMedium.ttf);
} */

body{
    font-family: 'montserrat', 'sans-serif';
    /* font-family: 'Roboto', sans-serif; */
    /* font-family: 'Poppins', sans-serif; */
}

.g-padding{
    padding:0 70px;
}
a{
    text-decoration: none;
}